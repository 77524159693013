import * as React from "react";
import ContactInfo from '../../data/contacts.json'

const Contact = () => {
    return (
        <section class="contact basic-block" id="contact">
            <div class="container">
                <header class="contact_header basic-header mb-5 pb-5">
                    <span class="basic-line">
                        <span class="basic-title my-3">Contact Us</span>
                    </span>
                </header>
                
                {ContactInfo.contact.map(info => ( 
                    <div class="contact-box text-center">
                        <address  class="d-block"> {info.address}</address>
                        <a href={`tel:${info.tel}`} class="d-block">{info.tel}</a>   
                        <a href={`tel:${info.tel2}`} class="d-block">Tel:        {info.tel2}</a>
                        <a href={`mailto: ${info.email}`} class="d-block">E-mail: {info.email}</a>    
                        <a href={info.presse} class="d-block">Presse</a>
                        <a href={info.stagerider} class="d-block">Stagerider </a>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Contact;